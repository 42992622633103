<template>
  <b-form @submit.prevent="save">
    <div class="title-actions">
      <h5 class="title">{{$t('changeGt')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <label for="idOrUsername">{{$t('enterIdOrUsername')}}</label>
      <b-form-input v-validate="'required|alpha_num'" name="idOrUsername" id="idOrUsername" v-model="idOrUsername" :state="validateState('idOrUsername')" />
      <span class="error-inputs">{{ errors.first('idOrUsername') }}</span>
    </div>
    <div class="form-group">
      <label for="nick">Nick</label>
      <b-form-input v-validate="'required'" name="nick" id="nick" v-model="nick" :state="validateState('nick')" data-vv-as="nick" />
      <span class="error-inputs">{{ errors.first('nick') }}</span>
    </div>
    <b-button type="submit" variant="primary" :disabled="loading">{{$t('save')}}</b-button>
    <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      idOrUsername: '',
      nick: '',
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang'
    ])
  },
  mounted() {
    this.changeTitleBar(this.$t('changeGt'))
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.$route.params.console}/super/change-nick`
          const payload = {
            code: this.idOrUsername,
            nick: this.nick
          }
          this.$axios.post(path, payload).then(() => {
            this.idOrUsername = ''
            this.nick = ''
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
